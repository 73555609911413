<template lang="pug">
  v-container#purchase-billing(fluid='' tag='section')
    v-dialog(v-model='dialogAdvances' width='700')
      v-card
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title Tiene Anticipos Pendientes
            v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="closeModalAdvaces")
              v-icon.primary.rounded mdi-close
        v-card-text
          h3 Lista de Anticipos
          v-data-table.mx-2#tableGuides(:search="search" :headers="headersAdvance" v-show="!firstLoad" :items="advanceLoadLst" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" :sort-desc="true" calculate-widths :footer-props="{\'items-per-page-text':'Guías por página'\}")
            template(v-slot:[`item.status`]='{ item }')
              v-chip(:color="getColor(item.status)" small label) {{ item.status ? item.status : 'PAGADO' }}
            v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
            | Sin resultados
            v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
              | Sin datos
            v-flex(xs12='', sm10='', offset-sm1='')
    v-dialog(fullscreen="" v-model='dialodInvMod')
      v-card
        inventoryDataModal(@closeModal="closeInventoryModal" :productToEdit='productToEdit' :forEditProduct='forEditProduct')
    v-dialog(fullscreen="" v-model='dialodProveedor')
      v-card
        registro-proveedor-modal(@closeModal="closeModalProvider" :providerToEdit='providerToEdit')
    v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-dialog(v-model='confirmationDialogProduct' persistent max-width='290')
      v-card
        v-card-title
          | Confirmar
        v-card-text ¿Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer(style="text-align: right;")
            v-btn(color='red darken-1' text='' @click='confirmationDialogProduct = false')
              | Cancelar
            v-btn.success-btn(text='' @click='acceptConfirmationDelete')
              | Aceptar
    v-dialog(v-model='dialogAddProduct' persistent max-width='500')
      v-card
        v-card-title.text-h5
          | Productos
        v-card-text(style="padding-top: 0px;")
          v-row(style="margin-bottom: 10px;")
            v-col(cols="6" style="padding: 0px;")
              h3(style="padding-left: 22px;") IVA: {{valIvaTxt}}
            //v-col(cols="4" style="padding: 0px;")
              v-checkbox(label="IVA CERO" style="margin: 0px;" v-model="product.porcentajecero")
            v-col(v-if="product.producto && product.producto.type.texto === 'BIEN'" cols="6" style="padding: 0px; text-align: right;")
              h3(style="padding-right: 25px;") STOCK: {{product.producto.quantity}}
          v-form(ref='formAddProduct' v-model='validAddProduct' lazy-validation='')
            v-row
              v-col(cols='10' md='11' style='padding-top: 0; padding-bottom: 0;')
                v-autocomplete(:rules='[rules.required]' @change="getProductValue(product, product.producto.activeValue, product.producto.tarifaiva.texto)" v-model='product.producto' :items='productsLst' return-object='return-object' item-text='completeData' label='Productos' autofocus)
              v-col.py-0.pl-0.ml-0(cols="2" md="1")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="factura.id" v-on="on" icon text color="purple darken-3" @click="addNewProduct" style='background:#B18BE2;')
                      v-icon(style="font-size: 25px;") mdi-plus
                  span.tooltips Crear Producto
              v-col(cols='4' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :rules='[rules.required]' label="Cantidad" v-model="product.cantidad")
              v-col(cols='4' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :rules='[rules.required]' label="Valor Unitario" v-model="product.valorUnitario")
              v-col(cols='4' style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(:rules='[rules.required]' label="Subtotal" v-model="product.total" type="text" @keypress="isNumber($event)" disabled)
              v-col(cols="12" md="6" style='padding-top: 0; padding-bottom: 0;')
                v-row(style='padding-top: 12px; padding-bottom: 0;')
                  v-switch(@change="calculateSubtotalKeyUp" color='primary' value='' v-model="banDescuento")
                  v-text-field.purple-input(type="text" @keypress="isNumber($event)" @keyup="calculateSubtotalKeyUp" :label="banDescuento ? 'DCTO. VALOR ($)' : 'DCTO. PORCENTAJE (%)'" v-model="dctoProduct")
              v-col(cols="12" md="6" style='padding-top: 0; padding-bottom: 0;')
                v-autocomplete(v-model="product.retencioniva" :items='arrRetencionIva' return-object item-text="completedata" label='Retención IVA')
              v-col(cols='12' md="6" style='padding-top: 0; padding-bottom: 0;')
                v-text-field.purple-input(@keyup="product.descripcion = product.descripcion.toUpperCase()" label="Observación" v-model="product.descripcion" type="text")
              v-col(cols="12" md="6" style='padding-top: 0; padding-bottom: 0;')
                v-autocomplete(v-model="product.retencionir" :items='arrRetencionIr' return-object item-text="completedata" label='Retención IR')
            v-card-actions
              v-spacer(style="text-align: right;")
                v-btn(color='red darken-1' text='' @click='dialogAddProduct = false')
                  | Cancelar
                v-btn.success-btn(text='' @click="addProduct")
                  | Aceptar
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Factura de Compra {{factura.numero}}
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModalPurchase')")
            v-icon.primary.rounded mdi-close
      v-card-text(style="padding-top: 30px;")
        v-row
          v-form(ref='formAddFactura' v-model='validAddFactura' lazy-validation='' style="padding: 5px; width: 100%; margin: 5px;")
            v-row
              //- Fecha de emision
              v-col.py-0(cols="12" md="3")
                v-menu(:disabled="factura.id" ref='startMenu1' v-model='startMenu1' :close-on-content-click='false' :nudge-right='40' :return-value.sync='factura.fechaEmision' transition='scale-transition' min-width='290px' offset-y='')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly dense="" :rules='[rules.required]' v-model='computedDateFormattedMomentjs' prepend-icon='mdi-calendar' v-on='on')
                      template(#label="")
                        span.red--text
                          strong *
                        | {{ ' ' + 'Fecha de Emisión' }}
                  v-date-picker.my-0(:rules='[rules.required]' v-model='factura.fechaEmision' no-title='' scrollable='' locale='es-ec' @change="$refs.startMenu1.save(factura.fechaEmision)" color="green lighten-1")
              //- Autorizacion de factura
              v-col.py-0(cols="12" md="3")
                v-text-field(:disabled="factura.id" dense="" v-model="factura.autorizacion" label="Autorización")
              //- Tipo de documento
              v-col.py-0(cols="12" md="3")
                v-select(:disabled="factura.id" dense="" :rules='[rules.required]' return-object v-model="factura.tipoDocumento" :items='typeLst' item-text='nombre' prepend-icon='mdi-format-list-bulleted')
                  template(#label="")
                    span.red--text
                      strong *
                    | {{ ' ' + 'Tipo de Documento' }}
              //- Numero de factura
              v-col.py-0(cols="12" md="3")
                v-text-field(:disabled="factura.id" dense="" :rules='[rules.required]' v-model="factura.numero")
                  template(#label="")
                    span.red--text
                      strong *
                    | {{ ' ' + 'No. Factura' }}
              v-col(cols="6" md="2" style="padding: 0px; padding-left: 12px;")
                v-checkbox(:disabled="factura.id" label="Pendiente de Pago" style="margin: 0px;" v-model="factura.pendientepago")
              v-col.py-0(cols="6" md="3")
                v-menu(:disabled="factura.id || !factura.pendientepago" ref='startMenu2' v-model='startMenu2' :close-on-content-click='false' :nudge-right='40' :return-value.sync='factura.fechaEmision' transition='scale-transition' min-width='290px' offset-y='')
                  template(v-slot:activator='{ on }')
                    v-text-field(readonly :disabled="factura.id || !factura.pendientepago" dense="" v-model='computedDateFormattedMomentPagojs' prepend-icon='mdi-calendar' v-on='on')
                      template(#label="")
                        | {{ ' ' + 'Fecha de Pago' }}
                  v-date-picker.my-0(v-model='factura.fechapago' no-title='' scrollable='' locale='es-ec' @change="$refs.startMenu2.save(factura.fechapago)")
              //- Formas de pago
              v-col.py-0(cols="12" md="3")
                v-autocomplete(:disabled="factura.id" :rules='[rules.required]' v-model="factura.formapago" required :items='formasPago' return-object item-text="name" dense='')
                  template(#label="")
                    span.red--text
                      strong *
                    | {{ ' ' + 'Forma de pago' }}
              v-col.py-0(cols="12" md="4")
                v-text-field(:disabled="factura.id" dense="" v-model="factura.numeroDocumento")
                  template(#label="")
                    | {{ ' ' + 'Número de Documento' }}
              //- Descripcion
              //v-col.py-0(cols="12" md="8")
                v-text-field(:disabled="factura.id" dense="" v-model="factura.descripcion")
                  template(#label="")
                    | {{ ' ' + 'Descripción' }}
              //- Proveedor
              v-col.py-0(cols="10" md="6")
                v-autocomplete(@change="getAdvacesData" dense="" :disabled="factura.id" :rules='[rules.required]' v-model='factura.proveedor' :items='arrayProveedores' return-object='return-object' item-text='completeData' label='Proveedor')
              //- Nuevo proveedor
              v-col.py-0.pl-0.ml-0(cols="2" md="1" style="text-align: center;")
                v-tooltip(bottom="")
                  template(v-slot:activator="{ on }")
                    v-btn(:disabled="factura.id" v-on="on" icon text color="purple darken-3" @click="dialodProveedor = true" style='background:#B18BE2;')
                      v-icon(style="font-size: 25px;") mdi-plus
                  span.tooltips Crear Proveedor
              v-col(v-if="!banInitAdvance" cols="12" md="5" style="padding-top: 0px")
                v-btn(:disabled="factura.id" color='green' @click="addNewElement" style="width: 100%;")
                  | AGREGAR PRODUCTOS
              v-col(v-if="banInitAdvance" cols="12" md="3" style="padding-top: 0px")
                v-btn(:disabled="factura.id" color='green' @click="addNewElement" style="width: 100%;")
                  | AGREGAR PRODUCTOS
              v-col(v-if="banInitAdvance" cols="12" md='2' style="padding-top: 0px")
                v-btn(:disabled="factura.id" color='orange' @click="dialogAdvances = true")
                  | ANTICIPOS
          v-row(style="padding-right: 20px;")
            v-col(cols="12" md="10")
              v-row
                v-col(cols="4" sm='2' class='addIcon').text-right
                  v-tooltip(bottom="")
                    template(v-slot:activator="{ on }")
                      v-btn(:disabled="factura.id" v-on="on" icon='' style='background: transparent;' color='green').mt-4
                        v-icon(style="font-size: 40px;" @click="addNewElement") mdi-clipboard-plus
                    span.tooltips Agregar Productos
                v-col(cols="12")
                  v-data-table(style="height: 300px; min-height: 400px; overflow-y: scroll;" :headers="headers" :items="factura.lstProductos" item-key="id" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :footer-props="{\'items-per-page-text':'Productos por página'\, 'items-per-page-options':[10, 20, 30, 50, 100]}" :items-per-page="100")
                    template(v-slot:item.cantidadView="{ item }")
                      p(style="font-size: 12px; font-weight: bold;") {{item.cantidad}}
                    template(v-slot:item.codView="{ item }")
                      p(style="font-size: 10px;") {{item.producto.codArticle}}
                    template(v-slot:item.nameView="{ item }")
                      p(style="font-size: 10px;") {{item.producto.name}}
                    template(v-slot:item.descuentoView="{ item }")
                      p(style="font-size: 12px;") ${{item.descuento}}
                    template(v-slot:item.valorUnitarioView="{ item }")
                      p(style="font-size: 10px;") ${{item.valorUnitario}}
                    template(v-slot:item.retencionirvalView="{ item }")
                      p(style="font-size: 10px;") ${{item.retencionirval}}
                    template(v-slot:item.retencionivavalView="{ item }")
                      p(style="font-size: 10px;") ${{item.retencionivaval}}
                    template(v-slot:item.totalView="{ item }")
                      p(style="font-size: 12px; font-weight: bold;") ${{item.total}}
                    template(v-slot:item.action="{ item }")
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(v-if="!factura.id" text="" icon="" color='blue' v-on="on" @click="editElement(item)")
                            v-icon(small) mdi-lead-pencil
                        span Editar
                      v-tooltip(bottom="")
                        template(v-slot:activator="{ on }")
                          v-btn(v-if="!factura.id" text="" icon="" color='red' v-on="on" @click="openDeleteItem(item)")
                            v-icon(small) mdi-trash-can-outline
                        span.tooltips Eliminar
                    v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                      | Sin resultados
                    v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                      | No se tiene datos
                    v-flex(xs12='', sm10='', offset-sm1='')
            v-col(cols="12" md="2")
              v-row
                v-col(cols="12" md="12" style="padding-right: 50px;")
                  v-row
                    v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                      label.label-tot(style=' font-size: 12px;') SUB TOTAL: ${{factura.subtotal}}
                    v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                      label.label-tot(style=' font-size: 12px;') RETENCIONES: ${{factura.retenciones}}
                    v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                      label.label-tot(style=' font-size: 12px;') IVA {{factura.porcentajeiva}}%: ${{factura.iva12}}
                    hr(style="border-color: white; width: 100%; margin: 5px -5px 5px 50px;")
                    v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                      label.label-tot(style='font-size: 16px; font-weight: bold;') TOTAL: ${{factura.total}}
                    //v-col(cols="12" md="12" style='width: 100%; text-align: right; padding: 0px;')
                      v-row
                        v-col(cols="7")
                          label.label-tot(style='width: 100px; font-size: 12px;') SALDO: ${{factura.saldo}}
              v-row
                v-col(cols="6" md="6").text-center
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ on, attrs }')
                      v-btn(:disabled="factura.id" icon='' v-on='on' color='orange' style="margin: 10px; background: transparent;").mt-4
                        v-icon(style="font-size: 40px;" @click="cleanOperation") mdi-file-refresh
                    span.tooltips Limpiar Todo
                v-col(cols="6" md="6").text-center
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ on, attrs }')
                      v-btn(:disabled="factura.id" icon='' v-on='on' color='primary' style="margin: 10px; background: transparent;" @click="guardarFactura" :loading="loadingSave").mt-4
                        v-icon(style="font-size: 40px;") mdi-content-save
                    span.tooltips Guardar
</template>
<script>
  import moment from 'moment'
  import * as vars from '../../../../vars.json'
  var accounting = require('accounting')
  export default {
    components: {
      InventoryDataModal: () => import('@/views/dashboard/component/application/InventoryDataModal'),
      RegistroProveedorModal: () => import('@/views/dashboard/component/application/RegistroProveedorModal'),
    },
    props: {
      facturaToEdit: {
        default: function () {
          return {
            client: null,
            porcentajeiva: '',
            cedula: '',
            // cadAutorizacion: moment(new Date()).format('YYYY-MM-DD'),,
            telefono: '',
            email: '',
            direccion: '',
            lstProductos: [],
            subtotal: '0.00',
            iva12: '0.00',
            total: '0.00',
            fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
            descripcion: '',
            formapago: null,
            proveedor: null,
            saldo: '0.00',
            plazodias: null,
            pendientepago: false,
            fechapago: moment(new Date()).format('YYYY-MM-DD'),
            numeroDocumento: '',
          }
        },
        type: Object,
      },
    },
    data: () => ({
      selectedProduct: null,
      dialogAdvances: false,
      banInitAdvance: false,
      firstLoad: false,
      ordenarPor: '',
      advanceLoadLst: [],
      headersAdvance: [
        { text: 'Fecha', value: 'createDate', align: 'left', filterable: true },
        { text: 'Proveedor', value: 'provider.name', align: 'left', filterable: true },
        { text: 'Total', value: 'total', align: 'left', filterable: true },
        { text: 'Pagado', value: 'paid', align: 'left', filterable: true },
        { text: 'Saldo', value: 'balance', align: 'left', filterable: true },
        { text: 'Estado', value: 'status', align: 'left', filterable: true },
      ],
      banDescuento: true,
      dctoProduct: '',
      valIvaTxt: '',
      forEditProduct: false,
      productToEdit: {
        codArticle: '',
        barcode: '',
        name: '',
        description: '',
        type: {
          codigo: '01',
          texto: 'BIEN',
        },
        quantity: '',
        activeValue: '',
        activeWValue: '',
        productKey: '',
        completeData: '',
        tarifaiva: {
          codigo: '2',
          texto: '12%',
          valor: '12',
        },
        irbpnr: '',
        tarifaice: '',
        totalValue: '',
      },
      dialodInvMod: false,
      dialodProveedor: false,
      providerToEdit: {
        name: '',
        mail: '',
        personType: ['Natural'],
        ruc: '',
        telephone: '',
        phone: '',
        description: '',
        providerKey: '',
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      validAddProduct: true,
      dialogAddProduct: false,
      confirmationDialogProduct: false,
      loadingSave: false,
      search: '',
      cargando: false,
      validAddFactura: true,
      startMenu1: false,
      startMenu2: false,
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      formasPago: [
        {
          code: '001', name: 'EFECTIVO',
        },
        {
          code: '002', name: 'TARJETA',
        },
        {
          code: '003', name: 'TRANSFERENCIA',
        },
        {
          code: '004', name: 'CRÉDITO',
        },
      ],
      plazoDias: [
        {
          code: '001', name: '15 DÍAS',
        },
        {
          code: '002', name: '20 DÍAS',
        },
        {
          code: '003', name: '30 DÍAS',
        },
      ],
      typeLst: [
        { codigo: '1', nombre: 'FACTURA' },
        // { codigo: '2', nombre: 'NOTA DE COMPRA' },
      ],
      headers: [
        { text: 'Cant.', value: 'cantidadView', align: 'left', filterable: true },
        { text: 'Cod.', value: 'codView', align: 'left', filterable: true },
        { text: 'Nombre', value: 'nameView', align: 'left', filterable: true },
        { text: 'Dcto.', value: 'descuentoView', align: 'left', filterable: true },
        { text: 'Val. Unit.', value: 'valorUnitarioView', align: 'left', filterable: true },
        { text: 'Ret. IR', value: 'retencionirvalView', align: 'left', filterable: true },
        { text: 'Ret. IVA', value: 'retencionivavalView', align: 'left', filterable: true },
        { text: 'Tot.', value: 'totalView', align: 'left', filterable: true },
        { text: 'Acciones', align: 'center', value: 'action', sortable: false }, // No se cambia
      ],
      factura: {},
      product: {
        producto: null,
        cantidad: '',
        descripcion: '',
        valorUnitario: '',
        total: '',
        porcentajecero: '',
        retencionir: '',
        retencioniva: '',
        retencionirval: '0',
        retencionivaval: '0',
      },
      banSendPurchaseBilling: '0',
    }),
    computed: {
      branchsSelected () {
        return this.$store.state.profile.branchSelected
      },
      computedDateFormattedMomentjs () {
        return this.factura.fechaEmision ? moment(this.factura.fechaEmision).format('DD/MM/YYYY') : ''
      },
      computedDateFormattedMomentPagojs () {
        return this.factura.fechapago ? moment(this.factura.fechapago).format('DD/MM/YYYY') : ''
      },
      arrayProveedores () {
        return this.$store.state.provider.lstProviders
      },
      productsLst () {
        return this.$store.state.product.lstProductsActive
      },
      arrRetencionIva () {
        return this.$store.state.ivaretenciones.retencionesIvaLst
      },
      arrRetencionIr () {
        return this.$store.state.ivaretenciones.retencionesIrLst
      },
      companyDataLoad () {
        return this.$store.state.facturacion.companydata
      },
    },
    watch: {
      ivaVal () {
        this.factura.porcentajeiva = vars.validIva
      },
      facturaToEdit () {
        this.factura = this.facturaToEdit
        this.factura.porcentajeiva = vars.validIva
        this.getAdvacesData()
      },
    },
    created () {
      this.factura.porcentajeiva = vars.validIva
    },
    mounted () {
      this.factura = this.facturaToEdit
      this.factura.porcentajeiva = vars.validIva
      this.getAdvacesData()
    },
    methods: {
      getColor (valor) {
        if (valor === true || valor !== 'PENDIENTE') {
          return 'green'
        } else {
          return 'red'
        }
      },
      closeModalAdvaces () {
        this.dialogAdvances = false
      },
      getAdvacesData () {
        this.$store.dispatch('advance/loadEndAdvanceProvider', this.factura.proveedor.ruc).then(res => {
          if (res) {
            if (res.length > 0) {
              this.advanceLoadLst = res
              this.banInitAdvance = true
              this.snackbar = {
                show: true,
                color: 'orange',
                text: 'Tiene Anticipos pendientes de Pago.',
              }
              setTimeout(() => {
                this.dialogAdvances = true
              }, 1000)
            }
          }
        })
      },
      editElement (item) {
        this.editProduct = true
        this.dialogAddProduct = true
        this.banDescuento = true
        this.product = item
        this.dctoProduct = this.product.descuento
        this.alertProductData = false
      },
      calculateSubtotalKeyUp () {
        if (this.product.producto) {
          if (this.product.valorUnitario === null || this.product.valorUnitario === '') {
            this.disableAdd = true
            this.msgAlertProducts = 'Debe agregar el precio unitario del producto.'
            this.alertProductData = true
            return
          }
          this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
          if (this.dctoProduct && this.dctoProduct.toString() !== '') {
            if (this.banDescuento) {
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(this.dctoProduct)), 3)
              this.product.descuento = this.dctoProduct
            } else {
              const valDcto = accounting.toFixed(((parseFloat(this.product.total) * parseFloat(this.dctoProduct)) / 100), 3)
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(valDcto)), 3)
              this.product.descuento = valDcto
            }
          }
          if (parseFloat(this.product.total) < 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'El descuento no puede ser mayor que el valor total a pagar por produto.'
            this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
            this.banDescuento = true
            this.dctoProduct = ''
            this.product.descuento = this.dctoProduct
          } else {
            this.alertProductData = false
          }
        }
      },
      async closeInventoryModal () {
        this.dialodInvMod = false
        if (this.selectedProduct) {
          this.getProductValue(this.selectedProduct, this.selectedProduct.producto.activeValue, this.selectedProduct.producto.tarifaiva.texto)
          this.selectedProduct = null
        }
      },
      addNewProduct () {
        this.forEditProduct = false
        this.dialodInvMod = true
        this.productToEdit = {
          codArticle: '',
          barcode: '',
          name: '',
          description: '',
          type: {
            codigo: '01',
            texto: 'BIEN',
          },
          quantity: '',
          activeValue: '',
          activeWValue: '',
          productKey: '',
          completeData: '',
          tarifaiva: {
            codigo: '2',
            texto: '12%',
            valor: '12',
          },
          irbpnr: '',
          tarifaice: '',
          totalValue: '',
        }
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      closeModalClient () {
        this.dialogCLient = false
      },
      closeModalProvider () {
        this.dialodProveedor = false
      },
      guardarFactura () {
        if (this.banSendPurchaseBilling === '0') {
          this.banSendPurchaseBilling = '1'
          setTimeout(() => {
            this.banSendPurchaseBilling = '0'
          }, 1000)
          if (this.companyDataLoad.obligadoContabilidad) {
            if (!this.factura.autorizacion || this.factura.autorizacion === '') {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Para continuar debe agregar la AUTORIZACIÓN de la FACTURA.',
              }
              return
            }
          }
          if (this.$refs.formAddFactura.validate()) {
            if (this.factura.lstProductos.length === 0) {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Para continuar debe agregar los productos.',
              }
              return
            }
            if (!this.factura.id) {
              this.loadingSave = true
              this.factura.estado = true
              this.factura.branch = this.branchsSelected
              this.$store.dispatch('factura/setFacturaPurchase', this.factura).then(res => {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Factura agregada de manera correcta.',
                }
                this.$emit('closeModalPurchase')
              }, reject => {
                this.loadingSave = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de guardar la factura.',
                }
              })
            }
          }
        }
      },
      acceptConfirmationDelete () {
        this.deleteItem(this.deleteItemSend)
        this.confirmationDialogProduct = false
      },
      openDeleteItem (item) {
        this.deleteItemSend = item
        this.confirmationDialogProduct = true
      },
      deleteItem (item) {
        let cont = 0
        let ban = true
        while (cont < this.factura.lstProductos.length && ban) {
          if (this.factura.lstProductos[cont] === item) {
            this.factura.lstProductos.splice(cont, 1)
            ban = false
          }
          cont++
        }
        this.calculateSubtotal()
        this.calculateVals()
      },
      addProduct () {
        if (this.$refs.formAddProduct.validate()) {
          if (parseFloat(this.product.cantidad) <= 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'La cantidad no puede ser menor de 1.'
            this.product.cantidad = 1
            this.calculateSubtotal()
            return
          }
          let cont = 0
          let ban = true
          if (!this.editProduct) {
            while (cont < this.factura.lstProductos.length && ban) {
              if (this.factura.lstProductos[cont].producto.codArticle.toString() === this.product.producto.codArticle.toString()) {
                ban = false
              }
              cont++
            }
            if (ban) {
              this.product.producto.description = this.product.producto.description.replace(/(\r\n|\n|\r)/gm, ' ')
              if (this.product.producto.description.length > 300) {
                this.product.producto.description = this.product.producto.description.substring(0, 299)
              }
              this.factura.lstProductos.push(this.product)
            } else {
              if (!ban) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Producto ya agregado dentro de la factura puede modificar su cantidad.',
                }
                this.dialogAddProduct = false
                return
              }
            }
          }
          this.dialogAddProduct = false
          this.viewTable = true
          this.calculateVals()
          this.product = {
            producto: null,
            cantidad: '',
            descripcion: '',
            valorUnitario: '',
            total: '',
            porcentajecero: '',
            retencionir: '',
            retencioniva: '',
            retencionirval: '0',
            retencionivaval: '0',
            cuentagasto: null,
            descuento: '',
          }
          this.dctoProduct = ''
          this.banDescuento = true
        }
      },
      calculateVals () {
        let retenciones = '0'
        let sub = 0
        let iva = 0
        let dcto = 0
        let subcero = 0
        let noobjiva = 0
        let exentoiva = 0
        let subProd = 0
        let ivaProd = 0
        let valDcto = 0
        for (let i = 0; i < this.factura.lstProductos.length; i++) {
          const valTot = parseFloat(this.factura.lstProductos[i].cantidad * this.factura.lstProductos[i].valorUnitario)
          subProd = (parseFloat(this.factura.lstProductos[i].cantidad * this.factura.lstProductos[i].valorUnitario))
          if (this.factura.lstProductos[i].descuento && this.factura.lstProductos[i].descuento.toString() !== '') {
            valDcto = parseFloat(this.factura.lstProductos[i].descuento)
            sub = sub + parseFloat(valTot) - parseFloat(valDcto)
            iva = iva + ((parseFloat(valTot) - parseFloat(valDcto)) * ((parseFloat(this.factura.lstProductos[i].producto.tarifaiva.valor)) / 100))
            ivaProd = ((parseFloat(valTot) - parseFloat(valDcto)) * ((parseFloat(this.factura.lstProductos[i].producto.tarifaiva.valor)) / 100))
            dcto = dcto + valDcto
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot) - parseFloat(valDcto)
            }
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot) - parseFloat(valDcto)
            }
          } else {
            sub = sub + parseFloat(valTot)
            iva = iva + ((parseFloat(valTot)) * ((parseFloat(this.factura.lstProductos[i].producto.tarifaiva.valor)) / 100))
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '0') {
              subcero = subcero + parseFloat(valTot)
            }
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '6') {
              noobjiva = noobjiva + parseFloat(valTot)
            }
            if (this.factura.lstProductos[i].producto.tarifaiva.codigo.toString() === '7') {
              exentoiva = exentoiva + parseFloat(valTot)
            }
          }
          if (!this.factura.lstProductos[i].retencionir) {
            this.factura.lstProductos[i].retencionir = ''
          }
          if (this.factura.lstProductos[i].retencionir !== '') {
            if (this.factura.lstProductos[i].retencionir.valorsobreporcentaje > 0) {
              const valSobrePorcentaje = parseFloat(subProd) * (parseFloat(this.factura.lstProductos[i].retencionir.valorsobreporcentaje) / 100)
              const val = parseFloat(valSobrePorcentaje) * (parseFloat(this.factura.lstProductos[i].retencionir.porcentaje) / 100)
              this.factura.lstProductos[i].retencionirval = accounting.toFixed(val, 3)
              retenciones = parseFloat(retenciones) + parseFloat(val)
            } else {
              const val = parseFloat(subProd) * (parseFloat(this.factura.lstProductos[i].retencionir.porcentaje) / 100)
              this.factura.lstProductos[i].retencionirval = accounting.toFixed(val, 3)
              retenciones = parseFloat(retenciones) + parseFloat(val)
            }
          }
          if (!this.factura.lstProductos[i].retencioniva) {
            this.factura.lstProductos[i].retencioniva = ''
          }
          if (this.factura.lstProductos[i].retencioniva !== '') {
            if (this.factura.lstProductos[i].retencioniva.valorsobreporcentaje > 0) {
              const valSobrePorcentaje = parseFloat(ivaProd) * (parseFloat(this.factura.lstProductos[i].retencioniva.valorsobreporcentaje) / 100)
              const val = parseFloat(valSobrePorcentaje) * (parseFloat(this.factura.lstProductos[i].retencioniva.porcentaje) / 100)
              this.factura.lstProductos[i].retencionivaval = accounting.toFixed(val, 3)
              retenciones = parseFloat(retenciones) + parseFloat(val)
            } else {
              const val = parseFloat(ivaProd) * (parseFloat(this.factura.lstProductos[i].retencioniva.porcentaje) / 100)
              this.factura.lstProductos[i].retencionivaval = accounting.toFixed(val, 3)
              retenciones = parseFloat(retenciones) + parseFloat(val)
            }
          }
        }
        const tot = parseFloat(accounting.toFixed(sub, 2)) + parseFloat(accounting.toFixed(iva, 2))
        const saldo = parseFloat(accounting.toFixed(tot, 2)) - parseFloat(accounting.toFixed(retenciones, 2))
        this.factura.retenciones = accounting.toFixed(retenciones, 2)
        this.factura.subtotal = accounting.toFixed(sub, 2)
        this.factura.subcero = accounting.toFixed(subcero, 2)
        this.factura.noobjiva = accounting.toFixed(noobjiva, 2)
        this.factura.excentoiva = accounting.toFixed(exentoiva, 2)
        this.factura.totdcto = accounting.toFixed(dcto, 2)
        this.factura.ice = '0.00'
        this.factura.irbpnr = '0.00'
        this.factura.iva12 = accounting.toFixed(iva, 2)
        this.factura.total = accounting.toFixed(tot, 2)
        this.factura.saldo = accounting.toFixed(saldo, 2)
      },
      getProductValue (product, activeVal, txtIva) {
        this.selectedProduct = product
        if (!product.producto.validChangeIva) {
          this.forEditProduct = true
          this.dialodInvMod = true
          this.productToEdit = product.producto
          return
        }
        product.valorUnitario = parseFloat(activeVal.toString().replace(',', '.'))
        this.valIvaTxt = txtIva
        this.calculateSubtotal()
      },
      addNewElement () {
        this.product = {
          producto: null,
          cantidad: '',
          descripcion: '',
          valorUnitario: '',
          total: '',
          porcentajecero: '',
          retencionir: '',
          retencioniva: '',
          retencionirval: '0',
          retencionivaval: '0',
          cuentagasto: null,
          descuento: '',
        }
        this.editProduct = false
        this.dialogAddProduct = true
        this.product.cantidad = 1
        this.banDescuento = true
        this.dctoProduct = ''
        this.alertProductData = false
        this.disableAdd = false
      },
      calculateSubtotal () {
        if (this.product.producto) {
          if (this.product.valorUnitario === null || this.product.valorUnitario === '') {
            this.disableAdd = true
            this.msgAlertProducts = 'Debe agregar el precio unitario del producto.'
            this.alertProductData = true
            return
          }
          if (this.product.producto.tarifaiva.valor.toString() !== '0') {
            const valMult = 1 + (parseFloat(this.product.producto.tarifaiva.valor) / 100)
            if (this.product.producto.activeWValue) {
              this.product.valorUnitario = (parseFloat(this.product.producto.activeWValue) / valMult).toFixed(3)
            } else {
              this.product.valorUnitario = parseFloat(this.product.producto.activeValue)
            }
          }
          this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
          if (this.dctoProduct && this.dctoProduct.toString() !== '') {
            if (this.banDescuento) {
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(this.dctoProduct)), 3)
              this.product.descuento = this.dctoProduct
            } else {
              const valDcto = accounting.toFixed(((parseFloat(this.product.total) * parseFloat(this.dctoProduct)) / 100), 3)
              this.product.total = accounting.toFixed((parseFloat(this.product.total) - parseFloat(valDcto)), 3)
              this.product.descuento = valDcto
            }
          }
          if (parseFloat(this.product.total) < 0) {
            this.alertProductData = true
            this.msgAlertProducts = 'El descuento no puede ser mayor que el valor total a pagar por produto.'
            this.product.total = accounting.toFixed((parseFloat(this.product.cantidad) * parseFloat(this.product.valorUnitario)), 3)
            this.banDescuento = true
            this.dctoProduct = ''
            this.product.descuento = this.dctoProduct
          } else {
            this.alertProductData = false
          }
        }
      },
      cleanOperation () {
        this.factura = {
          client: null,
          nombre: '',
          cedula: '',
          // cadAutorizacion: moment(new Date()).format('YYYY-MM-DD'),
          telefono: '',
          email: '',
          direccion: '',
          lstProductos: [],
          subtotal: '0.00',
          iva12: '0.00',
          total: '0.00',
          fechaEmision: moment(new Date()).format('YYYY-MM-DD'),
          descripcion: '',
          formapago: null,
          proveedor: null,
          saldo: '0.00',
          plazodias: null,
          pendientepago: false,
          fechapago: moment(new Date()).format('YYYY-MM-DD'),
        }
        this.factura.porcentajeiva = vars.validIva
      },
    },
  }
</script>
<style>
@media screen and (max-width: 500px) {
  .addButton {
    display: none;
  }
}
@media screen and (min-width: 500px) {
  .addIcon {
    display: none;
  }
}
</style>
